<template>
  <div class="contact-root-container">
    <div class="contact-label1">Contact</div>
    <!-- <div class="contact-label2">联系我们</div> -->
    <img :src="icons.triangle" style="width: 18px; height: 15px; margin-bottom: 35px" />
    <!-- <img :src="icons.award_liste" style="width: 450px; height: 277px" />
    <div class="contact-label3">明年1月开放</div> -->
    <div class="row contact-content-width" style="align-items: flex-start">
      <div class="column" style="margin-right: 50px">
        <img :src="icons.contact_bali_en" style="height: 246px" />
        <span class="contact-title" style="margin-top: 130px">Frane Office:</span>
        <span class="contact-title" style="margin-top: 20px">ADD：</span>
        <span class="contact-label3">27 Avenue Bugeaud,75016 Paris,France</span>
        <span class="contact-title" style="margin-top: 20px">E-mail：</span>
        <span class="contact-label3">Christine@chateaudeswann.com</span>
        <span class="contact-title" style="margin-top: 20px">TEL：</span>
        <span class="contact-label3">Xueling Li:+33768669707</span>
        <span class="contact-label3">Jia Li:+33783650806</span>
      </div>
      <div class="column">
        <img :src="icons.contact_beijing_en" style="height: 376px" />
        <span class="contact-title">Chinese Office:</span>
        <span class="contact-title" style="margin-top: 20px">ADD：</span>
        <span class="contact-label3">Room 210,Hanhai International Building,No.13 Jiuxianqiao Road,Chaoyang
          District,Beijing,China</span>
        <!-- <span class="contact-label3">（请在包装外注明“国际艺术联创巡展”，并在包裹内附带纸质作品信息表。）</span> -->
        <span class="contact-title" style="margin-top: 20px">E-mail：</span>
        <span class="contact-label3">ysld2021@126.com</span>
        <span class="contact-label3">ysld2021@qq.com</span>
        <span class="contact-title" style="margin-top: 20px">TEL：</span>
        <span class="contact-label3">Miss Anna:+86-13041129509</span>
        <span class="contact-label3">Miss Julie:+86-13801228556</span>
        <span class="contact-label3">Miss Sophnie:+86-15611824870</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as eventHelper from "@/utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);
  },
};
</script>

<style scoped>
.contact-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;
}
.contact-content-width {
  width: 800px;
}
.contact-label1 {
  margin-top: 50px;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.contact-label2 {
  margin-top: 10px;
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 20px;
}
.contact-title {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
}
.contact-label3 {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  letter-spacing: 0;
}
</style>